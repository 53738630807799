<template>
    <div>
        <template v-if="!isLoading">
            <TranslatedForm v-slot="{editingLang}">
                <form
                    class="form-2 spaced"
                    @submit.prevent=""
                >
                    <HtFormInput
                        id="name"
                        v-model.trim="document.resource.translations.name[editingLang]"
                        v-validate.disable="{
                            translation_default:[document.resource.translations.name, shared.session.company.company_languages.default.key],
                            max:255,
                        }"
                        :label="translate('Name')"
                        :data-vv-as="translate('name')"
                        name="name"
                        cypress="document-field-name"
                        show-asterisk
                    />

                    <LockedToggle
                        v-if="$can('AbstractManageLockedResources')"
                        v-model="document.is_locked"
                    />

                    <HtFormSwitch
                        v-if="canCreatePublicResource"
                        :id="'is_private'"
                        v-model="document.resource.is_private"
                        :description="translate('Mark this resource as private')"
                        :name="'is_private'"
                        :show-optional="false"
                        :label="translate('In my personal library')"
                        :cypress="'document-field-is-private'"
                    />

                    <fieldset class="medium">
                        <HtFormEditor
                            :id="'description'"
                            v-model="document.resource.translations.description[editingLang]"
                            :name="'description'"
                            :label="translate('Description')"
                            :has-toolbar="false"
                            :cypress="'document-field-description'"
                        />
                    </fieldset>

                    <fieldset :class="{ error: errors.has('files') }">
                        <TranslatableFiles
                            :files="document.file_translations"
                            :errors-messages="errors"
                            :editing-lang="editingLang"
                            :validation-rules="{
                                translation_default:[document.file_translations, shared.session.company.company_languages.default.key]
                            }"
                            @input="v => document.file_translations = v"
                        />
                    </fieldset>

                    <div v-show="editingLang === shared.session.company.company_languages.default.key">
                        <fieldset>
                            <HtFormSelect
                                id="category"
                                v-model="document.category"
                                v-validate.disable="'required'"
                                :label="translate('Category')"
                                name="category"
                                :data-vv-as="translate('category')"
                                :options="categoryOptionsSorted"
                            />
                        </fieldset>
                    </div>

                    <HtProgramList
                        v-if="document.resource.programs[0]"
                        ref="htProgramListInput"
                        v-model="document.resource.programs[0].company_program_id"
                        :required="true"
                        :with-default="false"
                        :disabled="disabledProgramList"
                    />

                    <div
                        v-if="isResourceTargeted"
                        v-show="editingLang === shared.session.company.company_languages.default.key"
                    >
                        <HtFormGroup
                            v-show="!document.resource.is_private"
                            :label="translate('Filters')"
                            :description="translate('Add filters to decide who can access the resource')"
                        >
                            <HtFormCard>
                                <ProgramResourceFilters
                                    v-if="document.resource.programs[0].company_program_id"
                                    v-model="document.resource.programs[0].filters"
                                    :program-id="document.resource.programs[0].company_program_id"
                                    :disabled="hasParent || hasChildren || useResourcePerimeter"
                                />

                                <div
                                    v-else
                                    class="sentence-filter"
                                >
                                    <t>Select a program to display associated filters</t>
                                </div>

                                <div
                                    v-if="hasParent || hasChildren"
                                    class="error-message"
                                >
                                    <t>You cannot change the filters because this task is nested</t>
                                </div>
                            </HtFormCard>
                        </HtFormGroup>

                        <HtFormCard
                            v-show="!document.resource.is_private"
                            class="mb-5"
                        >
                            <RetroactivityToggle
                                :is-retroactive.sync="isRetroactive"
                                :send-notif.sync="sendRetroactiveNotification"
                                :resource="'company_doc'"
                                :affected-programs="affectedPrograms"
                                :loading="affectedProgramsLoading"
                            />
                        </HtFormCard>

                        <HtKeyDatesInputOrder
                            :end-label="translate('Expected reading date')"
                            :end-tooltip="translate('Will add a reading action of this document to an enrollee')"
                            :program-id="document.resource.programs[0].company_program_id"
                            :resource-id="document.id"
                            :resource-type="resourceableClass"
                            :filters="document.resource.programs[0].filters"
                            :is-business-day.sync="document.resource.programs[0].is_business_day"
                            :offset-key-date.sync="document.resource.programs[0].company_program_key_date_id"
                            :offset-availability-number.sync="document.resource.programs[0].offset_availability_number"
                            :offset-availability-unit.sync="document.resource.programs[0].offset_availability_unit"
                            :offset-end-number.sync="document.resource.programs[0].offset_end_number"
                            :offset-end-unit.sync="document.resource.programs[0].offset_end_unit"
                            :dependents.sync="document.resource.programs[0].dependents"
                            :dependencies.sync="document.resource.programs[0].dependencies"
                        />
                    </div>
                </form>
            </TranslatedForm>

            <div class="modal-actions right mt-5">
                <Button
                    v-show="canDelete"
                    :state="buttonState"
                    class="negative"
                    cypress="document-delete"
                    @click="onDelete()"
                >
                    <t>Delete</t>
                </Button>

                <Button
                    :cypress="'document-form-submit'"
                    :state="buttonState"
                    @click="onValidate()"
                >
                    {{ labelButton }}
                </Button>
            </div>
        </template>
        <IconLoading v-else />
    </div>
</template>

<script>
import api from '@/store/api';
import TranslatedForm from '@/components/form/TranslatedForm.vue';
import CompanySharedDocument from '@/models/CompanySharedDocument';
import HtProgramList from '@/components/globals/HtProgramList.vue';
import RetroactivityToggle from '@/components/globals/RetroactivityToggle.vue';
import htFormGroup from '@/components/globals/HtFormGroup.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import TranslatableFiles from '@/components/form/TranslatableFiles.vue';
import LockedToggle from '@/components/globals/LockedToggle.vue';
import InputField from '@/components/globals/InputField.vue';
import HtFormEditor from '@/components/globals/HtFormEditor.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import Button from '@/components/globals/Button.vue';
import ProgramResourceFilters from '@/components/globals/ProgramResourceFilters.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import Notifier from '@/Notifier';
import I18n from '@/modules/i18n/I18n';
import IconLoading from '@/components/icons/IconLoading.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import cloneDeep from 'lodash.clonedeep';

export default {
    name: 'SharedDocumentEdit',
    components: {
        HtFormSwitch,
        HtKeyDatesInputOrder,
        IconLoading,
        ProgramResourceFilters,
        Button,
        HtFormSelect,
        HtFormEditor,
        InputField,
        HtProgramList,
        RetroactivityToggle,
        TranslatedForm,
        htFormGroup,
        HtFormCard,
        TranslatableFiles,
        LockedToggle,
    },
    inject: ['modal'],

    props: {
        id: {
            type: [String, Number],
            required: false,
        },
        companyProgramId: {
            type: Number,
            required: false,
        },
        showButtonDelete: {
            type: Boolean,
            default: true,
        },
        isDuplicableModel: {
            type: Boolean,
            default: false,
        },
        disabledProgramList: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: true,
            formLoading: false,
            document: {
                category: 'general',
                is_locked: false,
                is_heyteam: false,

                file_translations: {},

                resource: {
                    translations: {
                        name: {},
                        description: {},
                    },
                    is_private: false,

                    programs: [{
                        company_program_id: this.companyProgramId,
                        company_program_key_date_id: null,
                        offset_availability_number: 0,
                        offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                        offset_end_number: 0,
                        offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                        is_business_day: true,
                        is_enabled: true,
                        dependencies: [],
                        dependents: [],
                        participants: [],
                        filters: [],
                    }],
                },
            },
            categoryOptions: [
                {
                    id: 'general',
                    name: this.translate('General'),
                },
                {
                    id: 'administrative',
                    name: this.translate('Administrative'),
                },
            ],
            resourceableClass: CompanySharedDocument.RESOURCEABLE_CLASS,
            affectedPrograms: 0,
            affectedProgramsLoading: false,
            isRetroactive: false,
            sendRetroactiveNotification: false,
        };
    },

    computed: {
        userPerimeterEntities() {
            return this.$store.state.user.perimeterEntities;
        },

        useResourcePerimeter() {
            return this.$store.getters['permissions/useResourcePerimeter']('ModelCompanySharedDocument');
        },

        isResourceTargeted() {
            return this.document && this.document.resource.programs.length > 0 && !!this.document.resource.programs[0].company_program_id;
        },

        canCreatePublicResource() {
            const canCreatePublicResource = this.$canCreate('ModelCompanySharedDocument');

            return this.isNew && canCreatePublicResource;
        },

        isNew() {
            return this.isDuplicableModel || !this.id;
        },

        hasChildren() {
            return this.document.resource.programs.dependencies && this.document.resource.programs.dependencies.length > 0;
        },

        hasParent() {
            return this.document.resource.programs.dependents && this.document.resource.programs.dependents.length > 0;
        },

        labelButton() {
            return this.translate(this.isNew ? 'Add' : 'Save');
        },

        buttonState() {
            return this.formLoading ? 'loading' : 'idle';
        },

        canDelete() {
            return this.showButtonDelete === true && !this.isNew;
        },

        categoryOptionsSorted() {
            return this.categoryOptions.sort((a, b) => a.name.localeCompare(b.name));
        },
    },

    watch: {
        isRetroactive() {
            this.getUserProgramsAffected();
        },
    },

    mounted() {
        this.$store.dispatch('programs/fetchPrograms')
            .then(() => new Promise((resolve) => {
                if (this.id) {
                    api.configuration.documents.get(this.id)
                        .then(async (res) => {
                            const document = res.data.data;

                            const isResourceTargeted = document.resource.programs.length > 0;
                            if (!isResourceTargeted) {
                                // default values for the targeting
                                document.resource.programs[0] = {
                                    company_program_id: null,
                                    company_program_key_date_id: null,
                                    offset_availability_number: 0,
                                    offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                                    offset_end_number: 0,
                                    offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                                    is_business_day: true,
                                    is_enabled: true,
                                    dependencies: [],
                                    dependents: [],
                                    participants: [],
                                    filters: [],
                                };
                            }

                            this.document = document;

                            if (this.isDuplicableModel) {
                                await this.duplicateModel();
                            }

                            resolve();
                        });
                } else {
                    if (this.useResourcePerimeter) {
                        this.document.resource.programs[0].filters = this.userPerimeterEntities;
                    }

                    resolve();
                }
            }))
            .finally(() => {
                if (this.isNew) {
                    this.document.resource.is_private = !this.canCreatePublicResource;
                }

                this.afterFetch();
            });
    },

    beforeDestroy() {
        this.$eventHub.$off('filter-change', this.getUserProgramsAffected);
        this.$eventHub.$off('program-change', this.getUserProgramsAffected);
    },

    methods: {
        hasKeyDateTranslated(program) {
            return program?.key_date.name_translated !== null && program?.key_date.name_translated !== undefined;
        },

        async onValidate() {
            const isValid = await this.$validator.validateAll();
            if (!isValid) {
                return;
            }

            this.formLoading = true;

            const resourceToSave = cloneDeep({
                ...this.document,
                is_retroactive: this.isRetroactive,
                send_retroactive_notif: this.sendRetroactiveNotification,
            });
            if (!resourceToSave.resource.programs[0].company_program_id) {
                resourceToSave.resource.programs = [];
            }

            if (this.isNew) {
                api.configuration.documents
                    .create(resourceToSave)
                    .then(() => {
                        this.$emit('onSave');
                        this.modal.close();

                        Notifier.getInstance('App').showInfo(I18n.translate('Successfully added'));
                    })
                    .finally(() => this.formLoading = false);
            } else {
                api.configuration.documents
                    .update(this.id, resourceToSave)
                    .then(() => {
                        this.$emit('onSave');
                        this.modal.close();

                        Notifier.getInstance('App').showInfo(I18n.translate('Successfully updated'));
                    })
                    .finally(() => this.formLoading = false);
            }
        },

        onDelete() {
            this.modal
                .deleteWindow({
                    content: this.translate('Are you sure you want to delete this document? Once you click on delete, you will no longer be able to access this document.'),
                })
                .then((result) => {
                    if (result) {
                        this.formLoading = true;

                        api.configuration.documents
                            .delete(this.id)
                            .then(() => {
                                this.$emit('onDelete');
                                this.modal.close();

                                Notifier.getInstance('App').showInfo(I18n.translate('Successfully deleted'));
                            })
                            .finally(() => this.formLoading = false);
                    }
                });
        },

        afterFetch() {
            this.isLoading = false;

            if (this.isNew) {
                this.affectedPrograms = 0;
            }

            this.$eventHub.$on('filter-change', this.getUserProgramsAffected);
            this.$eventHub.$on('program-change', this.getUserProgramsAffected);

            this.modal.setTitle(this.translate(this.isNew ? 'Add document' : 'Edit document'));
        },

        getUserProgramsAffected() {
            if (this.isRetroactive === false) return;
            this.affectedProgramsLoading = true;
            const resourceProgram = this.isResourceTargeted ? this.document.resource.programs[0] : {};
            const programIds = this.isResourceTargeted ? [resourceProgram.company_program_id] : [];
            const entities = this.isResourceTargeted ? resourceProgram.filters.map((filter) => ({
                company_entity_id: filter.company_entity_id,
                company_entity_value_id: filter.company_entity_value_id,
            })) : [];

            this.$http.post('affected-programs-retroactivity', {
                task_type: CompanyUserProgramTask.TASK_COMPANY_USER_DOC,
                resource_id: this.id,
                entities,
                program_ids: programIds,
            }).then((response) => {
                this.affectedPrograms = response.data.count;
            }).finally(() => {
                this.affectedProgramsLoading = false;
            });
        },

        async duplicateModel() {
            const originalResourceProgram = { ...this.document.resource.programs[0] };
            const originalFileTranslations = { ...this.document.file_translations };

            this.document.id = null;
            this.document.is_locked = false;
            this.document.resource.id = null;
            this.document.resource.programs = [];
            this.document.resource.programs[0] = {};
            this.document.company_files = [];
            this.document.file_translations = {};

            for (const [key, value] of Object.entries(originalFileTranslations)) {
                const { data } = await api.configuration.documents.duplicateFile(this.id, value.id);

                this.document.file_translations = { ...{ [key]: data.resource }, ...this.document.file_translations };
            }

            this.document.resource.programs[0].company_program_id = this.companyProgramId;
            this.document.resource.programs[0].filters = [];
            this.document.resource.programs[0].company_program_key_date_id = null;
            this.document.resource.programs[0].offset_availability_number = originalResourceProgram.offset_availability_number;
            this.document.resource.programs[0].offset_availability_unit = originalResourceProgram.offset_availability_unit;
            this.document.resource.programs[0].offset_end_number = originalResourceProgram.offset_end_number;
            this.document.resource.programs[0].offset_end_unit = originalResourceProgram.offset_end_unit;
            this.document.resource.programs[0].is_business_day = originalResourceProgram.is_business_day;
            this.document.resource.programs[0].is_enabled = originalResourceProgram.is_enabled;
        },
    },
};
</script>
