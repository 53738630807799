<template>
    <HtModal ref="modal">
        <template #header>
            {{ title }}
        </template>
        <template #default>
            <div v-if="companyRoleUser">
                <div v-if="canEditable">
                    <t>A perimeter gives access to onboarding (or other movement) programs based on the selected entities. This means that a person can access routes (or other movement) without being part of the onboarding team.</t>
                </div>
                <div v-if="companyRole.name === CompanyRole.NAME_ADMIN">
                    <h2>{{ companyRole.alias_translated }}</h2>
                    {{ companyRole.is_heyteam ? translate(companyRole.description) : companyRole.description }}
                </div>
                <div
                    v-if="! hasRoleAndId"
                    class="mt-4"
                >
                    <FormInputUser
                        v-model="companyRoleUser.company_user_id"
                        :disabled="disabled"
                        :label="translate('User')"
                        :role-id="companyRole.id"
                    />
                </div>
                <div
                    v-if="displayFilters"
                    class="mt-5"
                >
                    <PerimeterFilters v-model="companyRoleUser" />
                </div>
                <div
                    v-if="displayEmployees"
                    class="employees-block mt-4"
                >
                    <div class="ht-form-label pb-4">
                        <t>Perimeter by user</t>
                    </div>
                    <div
                        v-for="employee in companyRoleUser.employees.models"
                        :key="employee.id"
                        class="employees-item"
                    >
                        <div
                            v-user-image="employee.user"
                            class="employees-item-avatar"
                        />
                        <div class="employees-item-fullname">
                            {{ employee.user.fullname }}
                        </div>
                    </div>
                </div>
            </div>
            <TransferOwnershipModal
                v-if="canTransfer"
                ref="transferOwnershipModal"
                :new-owner="companyRoleUser.user"
            />
        </template>
        <template #footer>
            <div class="actions">
                <Button
                    v-if="canTransfer"
                    :state="state"
                    @click="openTransferOwnershipModal()"
                >
                    <t>Transfer ownership</t>
                </Button>
                <Button
                    v-if="canDelete"
                    :state="state"
                    class="negative button-delete"
                    @click="destroy()"
                >
                    <t>Delete</t>
                </Button>
                <Button
                    v-if="canSave"
                    :state="state"
                    @click="save()"
                >
                    <t>Save</t>
                </Button>
                <Button
                    v-if="canUpdate"
                    :state="state"
                    @click="save()"
                >
                    <t>Update</t>
                </Button>
            </div>
        </template>
    </HtModal>
</template>

<script>
import Modalable from '@/Modalable';
import CompanyUser from '@/models/CompanyUser';
import CompanyRole from '@/models/CompanyRole';
import CompanyRoleUser from '@/models/CompanyRoleUser';
import PerimeterFilters from '@/components/globals/PerimeterFilters.vue';
import TransferOwnershipModal from './TransferOwnershipModal.vue';
import FormInputUser from './FormInputUser.vue';

export default {
    name: 'CompanyRoleUserModal',
    permissions: [
        'ModelCompanyUser',
    ],
    components: {
        FormInputUser,
        PerimeterFilters,
        TransferOwnershipModal,
    },
    props: {
        companyRole: {
            type: CompanyRole,
            required: true,
        },
    },
    data() {
        return {
            companyRoleUser: null,
            CompanyRole,
        };
    },
    computed: {
        state() {
            if (this.companyRoleUser && this.companyRoleUser._state.isSaving) {
                return 'loading';
            }
            return 'idle';
        },

        hasRoleAndId() {
            return this.companyRoleUser && this.companyRoleUser.id;
        },

        title() {
            if (this.hasRoleAndId) {
                return this.companyRoleUser.user.fullname;
            }

            return this.translate('Promote a user');
        },
        disabled() {
            return this.companyRoleUser && Number.isInteger(this.companyRoleUser.id);
        },
        displayEmployees() {
            return this.companyRoleUser
                && this.companyRoleUser.employees.length > 0;
        },
        displayFilters() {
            return this.companyRoleUser.company_user_id
                && this.canEditable;
        },
        canSave() {
            return this.companyRoleUser.id === undefined && this.companyRoleUser.company_user_id;
        },
        canUpdate() {
            return this.canEditable && this.companyRoleUser.id;
        },
        canDelete() {
            if (this.companyRole.name === CompanyRole.NAME_ADMIN && this.companyRoleUser.user && this.companyRoleUser.user.role !== CompanyUser.ROLE_SUPERADMIN) {
                return true;
            }

            if (this.companyRoleUser.employees.length > 0) {
                return false;
            }

            return this.canEditable && this.companyRoleUser.id;
        },
        canEditable() {
            const roles = [
                CompanyRole.NAME_ADMIN,
                CompanyRole.NAME_EMPLOYEE,
            ];

            return roles.includes(this.companyRole.name) === false;
        },
        canTransfer() {
            return this.$can('ModelCompanyUser', this.shared.session.companyUser, 'transferOwnership')
                && this.companyRoleUser
                && this.companyRoleUser.user
                && this.companyRoleUser.user.role !== 'superadmin'
                && this.companyRole.name === CompanyRole.NAME_ADMIN;
        },
    },
    methods: {
        async save() {
            await this.companyRoleUser.save();
            this.$emit('on-close');
            this.$refs.modal.close();
        },
        async destroy() {
            const accept = await Modalable.openRegisteredWindow('Deletewindow', {
                content: this.translate('Are you sure you want to remove this user from this role ?'),
            });

            if (accept) {
                await this.companyRoleUser.delete();
                this.$emit('on-close');
                this.$refs.modal.close();
            }
        },
        /**
         * @returns {Promise<CompanyRoleUser>}
         */
        async fetchCompanyRoleUser(companyRoleUserId = null) {
            if (companyRoleUserId === null) {
                return this.initCompanyRoleUser();
            }

            const companyRoleUser = this.initCompanyRoleUser().where([
                ['id', '=', companyRoleUserId],
            ]);

            await companyRoleUser.get();

            return companyRoleUser;
        },
        async open(companyRoleUserId = null) {
            this.companyRoleUser = await this.fetchCompanyRoleUser(companyRoleUserId);

            this.$refs.modal.open();
        },
        openTransferOwnershipModal() {
            this.$refs.transferOwnershipModal.open();
        },
        /**
         * @returns {CompanyRoleUser}
         */
        initCompanyRoleUser() {
            const companyRoleUser = new CompanyRoleUser([
                'id',
                'company_user_id',
                'company_role_id',
                'has_perimeter',
            ]).with({
                user: (query) => {
                    query
                        .select([
                            'id',
                            'fullname',
                            'role',
                        ])
                        .with({
                            company: (query) => {
                                query.select([
                                    'id',
                                    'url',
                                ]);
                            },
                        });
                },

                perimeters: (query) => {
                    query
                        .select([
                            'id',
                            'company_entity_id',
                            'company_role_user_id',
                            'company_entity_value_id',
                        ])
                        .with({
                            value: (query) => {
                                query.select([
                                    'id',
                                    'company_entity_id',
                                    'resourceable_type',
                                    'resourceable_id',
                                ]).with({
                                    jobPosition: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    contract: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    office: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    department: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    custom: (query) => {
                                        query.select([
                                            'id',
                                            'key',
                                            'name',
                                        ]);
                                    },
                                });
                            },
                        });
                },

                employees: (query) => {
                    query.select([
                        'id',
                        'company_role_user_id',
                        'company_user_id',
                    ]).with({
                        user: (query) => {
                            query.select([
                                'id',
                                'firstname',
                                'lastname',
                                'fullname',
                                'image',
                            ]);
                        },
                    });
                },
            });

            companyRoleUser.company_role_id = this.companyRole.id;

            return companyRoleUser;
        },

        onToggled(payload) {
            this.companyRoleUser.has_perimeter = payload;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.employees-block {
    background-color: $bg-grey;
    padding: 1.5em;
    border-radius: 5px;
    font-size: 1.4rem;
}
.employees {
    padding: 0 16px;
    background-color: $color-lightblue-grey;
    border-radius: 8px;
}
.employees-item {
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-top: 1px solid #EBECEC;

    &:last-child {
        padding-bottom: 0;
    }
}
.employees-item-avatar {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin-right: 12px;
}
.actions {
    display: flex;
    align-items: center;
    flex-direction: column;

    > {
        min-width: 70%;
        margin-top: 15px;
    }
}

h2 {
    margin-bottom: 1.2rem;
}
</style>
