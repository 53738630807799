<template>
    <div>
        <template v-if="isLoaded">
            <div
                v-for="entity in showedEntities"
                :key="entity.id"
            >
                <HtFormSelector
                    :id="`entity${entity.slug}`"
                    :value="selectedValuesByEntityId[entity.id]"
                    :options="valuesByEntityId[entity.id]"
                    :placeholder="entity.is_heyteam ? translate(entity.name) : entity.name"
                    track-by="company_entity_value_id"
                    :name="`entity${entity.slug}`"
                    :label="entity.is_heyteam ? translate(entity.name) : entity.name"
                    :is-deletable="programEntities.length === 0 && isCustomEntity(entity)"
                    :disabled="disabled"
                    label-options="name"
                    :show-optional="false"
                    :enable-selector="true"
                    @input="values => updateEntitiesFilter(entity, values)"
                    @onDeleteParent="removeCustomEntities(entity)"
                >
                    <div slot="noOptions">
                        <t>All filters already selected</t>
                    </div>
                </HtFormSelector>
            </div>

            <Button
                v-if="canAddCustomEntities"
                type="rounded"
                class="negative mb-5"
                @click="$refs.customEntitiesList.open(customEntities)"
            >
                <t>Add filter</t>
            </Button>

            <CustomEntitiesList
                v-if="canAddCustomEntities"
                ref="customEntitiesList"
                class="mt-3"
                :on-save="addCustomEntities"
            />
        </template>
        <IconLoading v-else />
    </div>
</template>

<script>
import CustomEntitiesList from '@/components/pages/resources/filters/CustomEntitiesList.vue';
import { mapGetters } from 'vuex';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import Button from '@/components/globals/Button.vue';
import groupBy from 'lodash.groupby';
import IconLoading from '@/components/icons/IconLoading.vue';
import api from '@/store/api';
import HtProgramType from '@/models/HtProgramType';
import uniqBy from 'lodash.uniqby';

export default {
    name: 'ProgramResourceFilters',
    components: {
        IconLoading,
        Button,
        HtFormSelector,
        CustomEntitiesList,
    },

    props: {
        value: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        programId: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            showedEntities: [],
            programEntities: [],
            filters: this.value,
            loading: true,
        };
    },

    watch: {
        value(val) {
            this.filters = val;
        },

        programId() {
            this.onProgramChanged();
        },
    },

    created() {
        this.$store.dispatch('entities/fetchEntities')
            .then(() => this.onProgramChanged(true));
    },

    computed: {
        ...mapGetters('entities', ['valuesByEntityId']),
        entities() {
            return this.$store.state.entities.entities.filter((entity) => !this.isCustomEntity(entity));
        },
        customEntities() {
            return this.$store.state.entities.entities.filter((entity) => this.isCustomEntity(entity));
        },
        isLoaded() {
            return this.$store.state.entities.entitiesLoaded && !this.loading;
        },
        selectedValuesByEntityId() {
            const entities = {};

            const valuesByEntityId = groupBy(this.filters, 'company_entity_id');

            this.showedEntities.forEach((entity) => {
                entities[entity.id] = (valuesByEntityId[entity.id] || []).map((value) => ({
                    ...value,
                    name: this.valuesByEntityId[value.company_entity_id].find((v) => v.company_entity_value_id === value.company_entity_value_id).name,
                }));
            });

            return entities;
        },
        canAddCustomEntities() {
            return this.programEntities.length === 0 && this.customEntities.length > 0;
        },
    },

    methods: {
        isCustomEntity(entity) {
            return !entity.is_heyteam || !entity.slug.includes('company_');
        },

        updateEntitiesFilter(entity, values) {
            const currentFilters = this.filters.filter((e) => e.company_entity_id !== entity.id);

            this.filters = [...currentFilters, ...values];

            this.onFiltersChange();
        },

        addCustomEntities(entities) {
            this.showedEntities = [...this.showedEntities, ...entities];
        },

        removeCustomEntities(entity) {
            this.showedEntities = this.showedEntities.filter((e) => e.id !== entity.id);
            this.filters = this.filters.filter((e) => e.company_entity_id !== entity.id);

            this.onFiltersChange();
        },

        onFiltersChange() {
            this.$emit('input', this.filters);
            this.$emit('on-change', this.filters);
            this.$eventHub.$emit('filter-change');
        },

        onProgramChanged(isInit = false) {
            this.programEntities = [];

            if (this.programId) {
                this.loading = true;

                api.configuration
                    .programs
                    .getProgram(this.programId)
                    .then((res) => {
                        if (res.data.type.slug !== HtProgramType.SLUG_DEFAULT) {
                            this.programEntities = res.data.entities.map((e) => e.company_entity_id);
                        }

                        this.initEntities(isInit);
                    });
            } else {
                this.initEntities(isInit);
            }
        },

        initEntities(isInit) {
            this.loading = true;

            const customEntitiesId = this.filters
                .filter((f) => !this.entities.map((e) => e.id).includes(f.company_entity_id))
                .map((f) => f.company_entity_id);

            const customEntitiesToLoad = this.customEntities
                .filter((e) => customEntitiesId.includes(e.id));

            this.showedEntities = [...this.entities, ...customEntitiesToLoad];

            if (this.programEntities.length > 0) {
                this.showedEntities = uniqBy([...this.showedEntities, ...this.customEntities].filter((entity) => this.programEntities.includes(entity.id)), 'id');
            }

            if (!isInit) {
                this.filters = [];
                this.onFiltersChange();
            }

            this.loading = false;
        },
    },
};
</script>
