<template>
    <transition name="fade">
        <div
            v-if="!formLoading"
            class="onboarding-settings-software"
        >
            <div>
                <div class="form-2 spaced">
                    <HtFormSelect
                        v-if="configurationOptions.length && !!software.configuration"
                        id="configuration"
                        v-model="software.configuration.id"
                        :label="translate('Configuration target')"
                        name="configuration"
                        :options="configurationOptions"
                        :show-optional="false"
                        :disabled="id"
                    />

                    <InputField
                        ref="nameInput"
                        v-model="software.resource.translations.name[shared.session.company.company_languages.default.key]"
                        cypress="softwares-field-name"
                        :label="translate('Name')"
                        :type="'text'"
                        :name="'name'"
                        :validate="'required|max:128'"
                        :validate-as="translate('name')"
                        :trim="true"
                    />

                    <fieldset class="medium">
                        <HtFormEditor
                            :id="'description'"
                            v-model="software.resource.translations.description[shared.session.company.company_languages.default.key]"
                            cypress="softwares-field-description"
                            :name="'description'"
                            :label="translate('Description')"
                            :has-toolbar="false"
                        />
                    </fieldset>

                    <HtProgramList
                        ref="htProgramListInput"
                        v-model="software.resource.programs[0].company_program_id"
                        :required="false"
                        :with-default="false"
                        :disabled="disabledProgramList"
                    />

                    <section v-if="isResourceTargeted">
                        <HtFormGroup
                            :label="translate('Filters')"
                            :description="translate('Add filters to decide who can access the resource')"
                        >
                            <HtFormCard>
                                <ProgramResourceFilters
                                    v-if="software.resource.programs[0].company_program_id"
                                    v-model="software.resource.programs[0].filters"
                                    :program-id="software.resource.programs[0].company_program_id"
                                    :disabled="hasParent || hasChildren || useResourcePerimeter"
                                />

                                <div
                                    v-else
                                    class="sentence-filter"
                                >
                                    <t>Select a program to display associated filters</t>
                                </div>

                                <div
                                    v-if="hasParent || hasChildren"
                                    class="error-message"
                                >
                                    <t>You cannot change the filters because this task is nested</t>
                                </div>
                            </HtFormCard>
                        </HtFormGroup>
                        <HtKeyDatesInputOrder
                            :end-label="translate('Installation date')"
                            :program-id="software.resource.programs[0].company_program_id"
                            :is-business-day.sync="software.resource.programs[0].is_business_day"
                            :offset-key-date.sync="software.resource.programs[0].company_program_key_date_id"
                            :offset-availability-number.sync="software.resource.programs[0].offset_availability_number"
                            :offset-availability-unit.sync="software.resource.programs[0].offset_availability_unit"
                            :offset-end-number.sync="software.resource.programs[0].offset_end_number"
                            :offset-end-unit.sync="software.resource.programs[0].offset_end_unit"
                        />
                    </section>
                </div>
                <div
                    class="modal-actions right"
                >
                    <Button
                        v-if="canDelete"
                        :state="buttonState"
                        class="negative"
                        data-cy="delete-software"
                        @click="onDelete()"
                    >
                        <t>Delete</t>
                    </Button>
                    <Button
                        :state="statusButton"
                        data-cy="add-softwares"
                        @click="onValidate()"
                    >
                        <t>Save</t>
                    </Button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import api from '@/store/api';
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import ProgramResourceFilters from '@/components/globals/ProgramResourceFilters.vue';
import HtProgramList from '@/components/globals/HtProgramList.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import cloneDeep from 'lodash.clonedeep';

export default {
    name: 'SoftwareEdit',
    components: {
        HtKeyDatesInputOrder,
        HtProgramList,
        HtFormCard,
        ProgramResourceFilters,
    },

    mixins: [
        DefaultFiltersMixin,
        DefaultOrderCompletionMixin,
    ],
    inject: ['modal'],

    props: {
        id: {
            type: Number,
            required: false,
        },
        disabledProgramList: {
            type: Boolean,
            default: false,
        },
        companyProgramId: {
            type: Number,
            required: false,
        },
        isDuplicableModel: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            formLoading: true,

            software: {
                id: null,
                configuration: {},
                resource: {
                    translations: {
                        name: {},
                        description: {},
                    },
                    programs: [
                        {
                            company_program_id: this.companyProgramId,
                            company_program_key_date_id: null,
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            is_enabled: true,
                            dependencies: [],
                            dependents: [],
                            participants: [],
                            filters: [],
                        },
                    ],
                },
            },

            resource: {
                name: '',
            },
        };
    },

    computed: {
        userPerimeterEntities() {
            return this.$store.state.user.perimeterEntities;
        },

        useResourcePerimeter() {
            return this.$store.getters['permissions/useResourcePerimeter']('ModelCompanySoftware');
        },

        configurations() {
            if (this.$canRead('PageSettingsIntegrations')) {
                return cloneDeep(this.$store.getters['configuration/configurations/getConfigurations']);
            }

            return [];
        },
        isResourceTargeted() {
            return this.software.resource.programs.length > 0 && !!this.software.resource.programs[0].company_program_id;
        },

        labelButton() {
            return this.translate('Add');
        },

        statusButton() {
            if (this.disabledButton) {
                return 'disabled';
            }

            return this.buttonState;
        },

        hasChildren() {
            return this.software.resource.programs[0].dependencies && this.software.resource.programs[0].dependencies.length > 0;
        },

        hasParent() {
            return this.software.resource.programs[0].dependents && this.software.resource.programs[0].dependents.length > 0;
        },

        canDelete() {
            return !this.isNew;
        },

        isNew() {
            return this.isDuplicableModel || this.id === null;
        },

        buttonState() {
            return this.formLoading ? 'loading' : 'idle';
        },

        configurationOptions() {
            return this.configurations.filter(
                (configuration) => configuration.is_enabled,
            ).map((configuration) => ({
                id: configuration.id,
                name: configuration.key,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
    },

    async mounted() {
        if (this.$canRead('PageSettingsIntegrations')) {
            await this.$store.dispatch('configuration/configurations/fetchConfigurations');
        }

        await this.$store.dispatch('programs/fetchPrograms');

        if (this.id) {
            api.configuration.softwares.get(this.id)
                .then((res) => {
                    const software = res.data.data;
                    const isResourceTargeted = software.resource.programs.length > 0;
                    if (!isResourceTargeted) {
                        // default values for the targeting
                        software.resource.programs[0] = {
                            company_program_id: null,
                            company_program_key_date_id: null,
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            is_enabled: true,
                            dependencies: [],
                            dependents: [],
                            participants: [],
                            filters: [],
                        };
                    }
                    this.software = software;

                    if (this.isDuplicableModel) {
                        this.duplicateModel();
                    }
                });
        } else if (this.useResourcePerimeter) {
            this.software.resource.programs[0].filters = this.userPerimeterEntities;
        }

        api.configuration.softwares.getAll().then((res) => {
            this.softwares = res.data.data;

            this.formLoading = false;
            this.afterFetch();
        });
    },

    methods: {
        hasKeyDateTranslated(program) {
            return program?.key_date.name_translated !== null && program?.key_date.name_translated !== undefined;
        },

        duplicateModel() {
            this.software.id = null;
            this.software.resource.id = null;
            this.software.resource.programs[0].id = null;
            this.software.resource.programs[0].company_program_id = this.companyProgramId;
            this.software.resource.programs[0].company_program_key_date_id = null;
            this.software.resource.programs[0].filters = [];
            this.software.resource.programs[0].dependents = [];
            this.software.resource.programs[0].dependencies = [];
        },

        async onValidate() {
            const isValid = await this.$validator.validateAll();

            if (isValid) {
                this.formLoading = true;

                const resourceToSave = cloneDeep(this.software);
                if (!resourceToSave.resource.programs[0].company_program_id) {
                    resourceToSave.resource.programs = [];
                }
                if (this.software.configuration && (!this.id || this.isDuplicableModel)) {
                    resourceToSave.company_configuration_id = this.software.configuration.id;
                }

                const fetchPromise = this.isNew
                    ? api.configuration.softwares.create(resourceToSave)
                    : api.configuration.softwares.update(this.id, resourceToSave);

                fetchPromise
                    .then(() => {
                        this.$emit('onSave');
                        this.modal.close();
                        this.$Notifier('App').showInfo(this.translate(`Successfully ${this.isNew ? 'added' : 'updated'}`));
                    })
                    .finally(() => this.formLoading = false);
            }
        },

        async onDelete() {
            this.modal
                .deleteWindow({
                    content: this.translate('Are you sure you want to delete this software? Once you click on delete, you will no longer be able to access this software.'),
                })
                .then((result) => {
                    if (result) {
                        this.formLoading = true;

                        api.configuration.softwares
                            .delete(this.id)
                            .then(() => {
                                this.$emit('onDelete');

                                this.modal.close();

                                this.$Notifier('App').showInfo(this.translate('Successfully deleted'));
                            })
                            .finally(() => this.formLoading = false);
                    }
                });
        },

        afterFetch() {
            this.modal.setTitle(this.translate(this.id ? 'Edit software' : 'Add software'));
        },
    },
};
</script>

<style lang="scss" scoped>
h2 {
    margin-top: 0;
}
h4{
    margin-top:2rem;
    margin-bottom:2rem;
}
.software-disabled {
    transition: opacity 0.5s;
    opacity: 0.4;

    .item-content {
        display: none;
    }
}

.software-custom-input {
    margin-bottom: 3em;
}

.margin-bottom-3 {
    margin-bottom: 30px !important
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-padding {
    padding: 0 !important;
}
</style>

<style lang="scss">
.selected.no-check {
    .card-body:after {
        content: none !important;
    }
}
</style>
