import Model from '@tony.caron/node-model-proxy/Model';
import CompanyLanguageCollection from './CompanyLanguageCollection';
import I18n from '../modules/i18n/I18n';

export default class CompanyLanguage extends Model {
    static KEY_FR = 'fr';

    static KEY_EN = 'en';

    static KEY_DE = 'de';

    static KEY_IT = 'it';

    modelConfig() {
        return {
            collection: CompanyLanguageCollection,
        };
    }

    modelCustomAttributes() {
        return {
            codeFlag: null,
            codeTinymce: null,
        };
    }

    modelAccessors() {
        return {
            codeFlag: () => {
                let code = null;
                switch (this.key) {
                case 'el':
                    code = 'gr';
                    break;
                case 'en':
                    code = 'gb';
                    break;
                case 'zh':
                case 'zh-TW':
                    code = 'cn';
                    break;
                case 'ja':
                    code = 'jp';
                    break;
                case 'ko':
                    code = 'kr';
                    break;
                case 'sr':
                    code = 'rs';
                    break;
                case 'sl':
                    code = 'si';
                    break;
                case 'ar':
                    code = 'ae';
                    break;
                case 'he':
                    code = 'il';
                    break;
                case 'et':
                    code = 'ee';
                    break;
                default:
                    code = this.key;
                }
                return code;
            },

            codeTinymce: () => {
                let code = null;
                switch (this.key) {
                case 'fr':
                    code = 'fr_FR';
                    break;
                case 'pt':
                    code = 'pt_BR';
                    break;
                case 'zh':
                    code = 'zh-Hans';
                    break;
                case 'zh-TW':
                    code = 'zh-Hant';
                    break;
                case 'ko':
                    code = 'ko_KR';
                    break;
                case 'sl':
                    code = 'sl_SI';
                    break;
                case 'he':
                    code = 'he_IL';
                    break;
                case 'hu':
                    code = 'hu_HU';
                    break;
                default:
                    code = this.key;
                }
                return code;
            },

            name: (n) => I18n.translate(n),
        };
    }
}
