import Api from '@/services/Api';
import moment from 'moment';
import I18n_component from './I18n.vue';

export default class I18n {
    static Vue = null;

    static langPack = null;

    static setTranslationPack(o) {
        I18n.langPack = o;
    }

    // Used from router
    static getTranslationPack(sLang = 'en') {
        moment.locale(sLang === 'fr' ? 'fr' : 'en');

        const aFallback = ['fr', 'en', 'de', 'ro', 'it', 'pt', 'sk', 'nl', 'es', 'zh', 'zh-TW', 'el', 'ja', 'ko', 'hr', 'sr', 'sl', 'ar', 'he', 'lv', 'lt', 'et', 'hu']; // list of language handle by the system of ht_locale
        if (!aFallback.includes(sLang)) sLang = 'en';

        return new Promise((resolve, reject) => {
            Api.getInstance('qa').get(`ht_locale/${sLang}`, {
                200: (data) => {
                    I18n.setTranslationPack(data);
                    resolve();
                },
                onError: () => {
                    reject();
                },
            });
        });
    }

    // Used only locally and on qa to translate in a modal
    static getTranslation(key) {
        return new Promise((resolve, reject) => {
            Api.getInstance('qa').get(`admin/ht_locale?per_page=1&search=${key}`, {
                200: ({ data }) => {
                    resolve(data.data[0]);
                },
                onError: (data, code) => {
                    reject(data, code);
                },
            });
        });
    }

    static saveTranslation(ht_locale) {
        return new Promise((resolve, reject) => {
            Api.getInstance('qa').put(`admin/ht_locale/${ht_locale.id}`, { ht_locale }, {
                200: (data) => {
                    resolve(data);
                },
                onError: (data, code) => {
                    reject(data, code);
                },
            });
        });
    }

    static deleteTranslation(ht_locale) {
        return new Promise((resolve, reject) => {
            Api.getInstance('qa').delete(`admin/ht_locale/${ht_locale.id}`, {
                200: (data) => {
                    resolve(data);
                },
                onError: (data, code) => {
                    reject(data, code);
                },
            });
        });
    }

    /**
	 * Executed with the component scope
	 *
	 * @returns {string}
	 */
    static translate(value, param = {}) {
        if (!I18n.Vue) console.log('Vue not yet loaded %', value);

        const new_value = value;

        if (!I18n.isPackLoaded()) return I18n.mapVariables(new_value, param);

        if (!I18n.translationKeyExists(value)) {
            if (I18n.Vue.prototype.$env.get('APP_ENV') !== 'production') {
                I18n.saveMissing(value, this);
            }

            return I18n.mapVariables(new_value, param);
        }

        if (!I18n.translationExists(value)) {
            return I18n.mapVariables(new_value, param);
        }

        if (!I18n.isPlural(param)) return I18n.mapVariables(I18n.langPack[value], param);

        const splitted = I18n.langPack[value].split('|');
        let count = parseInt(param.count, 10);
        count = (undefined === count || isNaN(count)) ? 0 : count;

        let index = Math.min(splitted.length > 2 ? count : count - 1, splitted.length - 1);

        index = index < 0 ? 0 : index;

        return I18n.mapVariables(splitted[index].trim(), param);
    }

    static getRawTranslation(value) {
        if (I18n.translationExists(value)) return I18n.translationExists(value) ? I18n.langPack[value] : '';
    }

    static saveMissing(value, component) {
        if (I18n.Vue.prototype.$env.get('APP_ENV') == 'production'
            || I18n.Vue.prototype.$env.get('APP_ENV') == 'local') return;

        // TODO SAVE ON QA !

        const ht_locale = {
            key: value,
            en: value,
            component_name: component == I18n ? '' : component.$options.name,
            route: component == I18n ? '' : component.$route.fullPath,
        };

        if (value) {
            Api.getInstance('qa').post('admin/ht_locale', { ht_locale }, {
                200: () => {
                },
                onError: () => {

                },
            });
        }
    }

    // Test if the string will need a plural form
    static isPlural(param) {
        return undefined !== param.count;
    }

    // Check if the key exists
    static translationKeyExists(key) {
        return undefined !== I18n.langPack[key];
    }

    // Check if the translation exists
    static translationExists(key) {
        return I18n.translationKeyExists(key) && (I18n.langPack[key] !== null && I18n.langPack[key] !== '');
    }

    // Map variable to string (toto => {name})
    static mapVariables(value, param) {
        if (typeof value !== 'string' && !(value instanceof String)) return;

        for (const n in param) {
            if (Object.prototype.hasOwnProperty.call(param, n)) {
                const replace = `\{[ ]?${n}[ ]?\}`;
                const re = new RegExp(replace, 'g');
                value = value.replace(re, param[n]);
            }
        }

        return value;
    }

    static isPackLoaded() {
        return I18n.langPack !== null;
    }

    static install(Vue) {
        I18n.Vue = Vue;

        Vue.component('T', I18n_component);

        Vue.mixin({
            created() {
                // Allow filter
                this.$options.filters.t = I18n.translate.bind(this);
            },

            methods: {
				 // eslint-disable-next-line no-unused-vars
				 translate(value, param = {}, renderError = false, scope_component = this) {
				 	return I18n.translate.apply(scope_component, arguments);
				 },
				 getRawTranslation(value, scope_component = this) {
				 	return I18n.getRawTranslation.apply(scope_component, arguments);
				 },
            },
        });

        Vue.prototype.$I18n = I18n;
    }
}
