<template>
    <transition name="fade">
        <div
            v-if="nudgeLoaded"
            class="nudge-edit"
        >
            <TranslatedForm
                v-slot="{editingLang}"
                v-model="currentLanguage"
            >
                <div class="form-2 spaced">
                    <HtFormInput
                        id="name"
                        v-model.trim="nudge.resource.translations.name[editingLang]"
                        v-validate.disable="isDefaultLangSelected ? 'required|max:255' : 'max:255'"
                        :label="translate('Name of the nudge')"
                        :data-vv-as="translate('name of the nudge in default language')"
                        name="name"
                        cypress="nudge-name"
                        show-asterisk
                    />

                    <HtFormEditor
                        :id="'message'"
                        ref="messageInput"
                        v-model="nudge.translations.message[editingLang]"
                        v-validate.disable="isDefaultLangSelected ? 'required|max_without_html:500' : 'max_without_html:500'"
                        class="mb-4 w-100"
                        :name="'message'"
                        :label="translate('Message of the nudge')"
                        :data-vv-as="translate('message in default language')"
                        :variables="fillVariables"
                        cypress="nudge-message"
                        resource-openai="nudge"
                    />
                    <HtFormUserOrRoleSelect
                        :id="'nudge_initialisator'"
                        v-model="getInitialisatorFromNudge"
                        v-validate.disable="'required'"
                        :data-vv-as="translate('selection of a person initiating the nudge')"
                        :label="translate('Person initiating the challenge')"
                        :name="'nudgeInitialisator'"
                        :type="getTypeOfInitialisator"
                        :show-asterisk="true"
                        cypress="nudge_initialisator"
                        @on-add="openAddInitiatorUsersModal"
                        @on-remove="onRemoveInitiator"
                    />

                    <fieldset :class="[{ error: errors.has('nudge_recipients') }, 'medium']">
                        <label for="nudge_recipients">
                            <t>Person receiving the challenge</t>
                        </label>
                        <transition-group
                            name="fade"
                            class="card-6 flex-container"
                        >
                            <template
                                v-if="nudge.resource.programs[0].participants.length"
                            >
                                <div
                                    v-for="(recipient, index) in nudge.resource.programs[0].participants"
                                    :key="index"
                                    style="display:flex"
                                    class="currentNudge"
                                >
                                    <template>
                                        <UserBlock2
                                            v-if="recipient.role"
                                            :key="index"
                                            v-model="nudge.resource.programs[0].participants[index].role"
                                            :type="'role'"
                                            @onRemove="onRemoveRecipient(recipient)"
                                        />
                                    </template>
                                </div>
                            </template>
                            <UserBlock2
                                ref="nudge_recipientsInput"
                                :key="-1200"
                                cypress="add-nudge-recipients"
                                @onAdd="openAddUsersModal"
                            />
                        </transition-group>

                        <input
                            id="nudge_recipients"
                            v-validate.disable="'required|array'"
                            v-next-field
                            type="hidden"
                            name="nudge_recipients"
                            :value="nudge.resource.programs[0].participants.length ? '-' : ''"
                            class="full-width"
                            :data-vv-as="translate('selection of at least one recipient')"
                        >
                        <div
                            v-for="(errorMessage, index) in errors.collect('nudge_recipients')"
                            :key="index"
                            class="error-message"
                        >
                            {{ errorMessage }}
                        </div>
                    </fieldset>

                    <HtProgramList
                        ref="htProgramListInput"
                        v-model="nudge.resource.programs[0].company_program_id"
                        :required="false"
                        :with-default="false"
                        :disabled="false"
                    />

                    <section v-if="isResourceTargeted">
                        <HtFormSwitch
                            :id="'toggle-is-enabled'"
                            v-model="nudge.resource.programs[0].is_enabled"
                            :label="translate('Enabled')"
                            :on-label="translate('On')"
                            :off-label="translate('Off')"
                            :name="'toggle-is-enabled'"
                            :show-optional="false"
                        />

                        <fieldset
                            v-if="nudge.resource.programs[0].company_program_id"
                            class="no-margin"
                        >
                            <HtFormGroup
                                :label="translate('Filters')"
                                :description="translate('Add filters to decide who can access the resource')"
                            >
                                <HtFormCard class="mb-5">
                                    <ProgramResourceFilters
                                        v-model="nudge.resource.programs[0].filters"
                                        :program-id="nudge.resource.programs[0].company_program_id"
                                        :disabled="useResourcePerimeter"
                                    />
                                </HtFormCard>
                            </HtFormGroup>
                        </fieldset>

                        <HtKeyDatesInputOrder
                            :end-label="translate('Challenge submission date')"
                            :end-has-tooltip="false"
                            :program-id="nudge.resource.programs[0].company_program_id"
                            :is-business-day.sync="nudge.resource.programs[0].is_business_day"
                            :offset-key-date.sync="nudge.resource.programs[0].company_program_key_date_id"
                            :offset-availability-number.sync="nudge.resource.programs[0].offset_availability_number"
                            :offset-availability-unit.sync="nudge.resource.programs[0].offset_availability_unit"
                            :offset-end-number.sync="nudge.resource.programs[0].offset_end_number"
                            :offset-end-unit.sync="nudge.resource.programs[0].offset_end_unit"
                        />
                    </section>

                    <fieldset :class="[{ error: errors.has('time_to_complete') }, 'medium']">
                        <div class="full-width">
                            <label>
                                <t>Time to complete the challenge from its acceptance</t>
                                *
                            </label>
                        </div>
                        <div class="input-order">
                            <input
                                ref="inputDisplayOrder"
                                v-model="nudge.time_to_complete"
                                v-validate.disable="'required'"
                                v-next-field
                                type="number"
                                name="time_to_complete"
                                min="1"
                                :data-vv-as="translate('time to complete the challenge from its acceptance')"
                                cypress="time-to-complete-defit"
                            >
                            <div class="input-order-text">
                                <t :count="nudge.time_to_complete ? nudge.time_to_complete:1">
                                    day|days
                                </t>
                            </div>
                        </div>
                        <div
                            v-for="(errorMessage, index) in errors.collect('time_to_complete')"
                            :key="index"
                            class="error-message"
                        >
                            {{ errorMessage }}
                        </div>
                    </fieldset>
                </div>
                <div class="modal-actions right">
                    <Button
                        v-if="id"
                        :state="buttonState"
                        class="negative"
                        cypress="nudge-delete"
                        @click="onDestroyModal()"
                    >
                        <t>Delete</t>
                    </Button>
                    <Button
                        :state="buttonState"
                        cypress="nudge-save"
                        @click="onValidate()"
                    >
                        <t>Save</t>
                    </Button>
                </div>
            </TranslatedForm>

            <modalable
                ref="modalableSearchRolePanel"
                class="modalable-1 small"
            >
                <SearchRolePanel
                    ref="searchRolePanel"
                    :allow-user-search="allowUserSearch"
                    :is_assignable_onboardee="isAssignableOnboardee"
                    :multiple-mode="isSearchUserMultipleMode"
                    @onValidate="$refs.modalableSearchRolePanel.close()"
                />
            </modalable>
        </div>
    </transition>
</template>

<script>
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import HtProgramList from '@/components/globals/HtProgramList.vue';
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import HtFormUserOrRoleSelect from '@/components/globals/HtFormUserOrRoleSelect.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import api from '@/store/api';
import TranslatedForm from '@/components/form/TranslatedForm.vue';
import ProgramResourceFilters from '@/components/globals/ProgramResourceFilters.vue';
import I18n from '@/modules/i18n/I18n';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import cloneDeep from 'lodash.clonedeep';

export default {
    name: 'NudgeEdit',
    components: {
        HtKeyDatesInputOrder,
        HtProgramList,
        HtFormUserOrRoleSelect,
        HtFormSwitch,
        HtFormCard,
        TranslatedForm,
        ProgramResourceFilters,
    },

    mixins: [
        DefaultOrderCompletionMixin,
        DefaultFiltersMixin,
    ],

    inject: ['modal', '$validator'],

    props: {
        id: {
            type: Number,
        },
        companyProgramId: {
            type: Number,
        },
        isDuplicableModel: {
            type: Boolean,
        },
    },

    data() {
        return {
            nudge: {
                resource: {
                    programs: [
                        {
                            company_program_id: this.companyProgramId,
                            company_program_key_date_id: null,
                            offset_availability_number: 0,
                            offset_availability_unit: 'immediately',
                            offset_end_number: 0,
                            offset_end_unit: 'days',
                            is_business_day: true,
                            is_enabled: true,
                            dependencies: [],
                            dependents: [],
                            participants: [],
                            filters: [],
                        },
                    ],
                    translations: {
                        name: {},
                        description: {},
                    },
                },
                translations: {
                    message: {},
                },
                company_nudge_initiator_role_id: null,
                company_nudge_initiator_user_id: null,
                company_nudge_initiator_role: null,
                company_nudge_initiator_user: null,
            },
            allowUserSearch: true,
            isSearchUserMultipleMode: true,
            isAssignableOnboardee: false,
            nudgeLoaded: false,
            buttonState: 'idle',
            currentLanguage: '',
        };
    },

    computed: {
        userPerimeterEntities() {
            return this.$store.state.user.perimeterEntities;
        },

        useResourcePerimeter() {
            return this.$store.getters['permissions/useResourcePerimeter']('ModelCompanyNudge');
        },

        isResourceTargeted() {
            return this.nudge.resource.programs.length > 0 && !!this.nudge.resource.programs[0].company_program_id;
        },

        fillVariables() {
            return [
                { name: 'enrollee_firstname', label: 'Enrollee firstname' },
                { name: 'enrollee_lastname', label: 'Enrollee lastname' },
                { name: 'buddy_name', label: 'Buddy name' },
                { name: 'manager_name', label: 'Manager name' },
                { name: 'company_name', label: 'Company name' },
                { name: 'admin_responsible_name', label: 'Admin responsible name' },
            ];
        },

        modalResourceName() {
            return 'nudge';
        },

        getInitialisatorFromNudge() {
            if (this.nudge.company_nudge_initiator_user) return this.nudge.company_nudge_initiator_user;
            if (this.nudge.company_nudge_initiator_role) return this.nudge.company_nudge_initiator_role;
            return null;
        },

        getTypeOfInitialisator() {
            return this.nudge.company_nudge_initiator_role ? 'role' : 'user';
        },

        hasInitiator() {
            return this.nudge.isLoaded()
                && (this.companyNudgeInitiator.company_role !== null
                    || this.companyNudgeInitiator.company_user !== null);
        },

        isDefaultLangSelected() {
            return this.shared.session.company.company_language.key === this.currentLanguage;
        },

        companyNudgeInitiator: {
            get() {
                if (this.nudge) {
                    return {
                        company_role_id: this.nudge.company_nudge_initiator_role ? this.nudge.company_nudge_initiator_role.id : null,
                        company_role: this.nudge.company_nudge_initiator_role,
                        company_user_id: this.nudge.company_nudge_initiator_user ? this.nudge.company_nudge_initiator_user.id : null,
                        company_user: this.nudge.company_nudge_initiator_user,
                    };
                }
            },
            set(selectedInitiator) {
                if (selectedInitiator && selectedInitiator.company_role) {
                    this.nudge.company_nudge_initiator_user_id = null;
                    this.nudge.company_nudge_initiator_user = null;
                    this.nudge.company_nudge_initiator_role_id = selectedInitiator.company_role.id;
                    this.nudge.company_nudge_initiator_role = selectedInitiator.company_role;
                } else if (selectedInitiator && selectedInitiator.company_user) {
                    this.nudge.company_nudge_initiator_role_id = null;
                    this.nudge.company_nudge_initiator_role = null;
                    this.nudge.company_nudge_initiator_user_id = selectedInitiator.company_user.id;
                    this.nudge.company_nudge_initiator_user = selectedInitiator.company_user;
                } else {
                    this.nudge.company_nudge_initiator_role_id = null;
                    this.nudge.company_nudge_initiator_role = null;
                    this.nudge.company_nudge_initiator_user_id = null;
                    this.nudge.company_nudge_initiator_user = null;
                }
            },
        },
        /**
         * @returns {number|undefined}
         */
        selectedProgramId() {
            return this.nudge.resource_program.models[0].company_program_id;
        },

    },

    async created() {
        this.currentLanguage = this.shared.session.company.company_language.key;
        if (this.id) {
            const nudge = (await api.configuration.nudges.get(this.id)).data.data;

            const isResourceTargeted = nudge.resource.programs.length > 0;
            if (!isResourceTargeted) {
                // default values for the targeting
                nudge.resource.programs[0] = {
                    company_program_id: null,
                    company_program_key_date_id: null,
                    offset_availability_number: 0,
                    offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                    offset_end_number: 0,
                    offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                    is_business_day: true,
                    is_enabled: true,
                    dependencies: [],
                    dependents: [],
                    participants: [],
                    filters: [],
                };
            }

            this.nudge = nudge;
        } else if (this.useResourcePerimeter) {
            this.nudge.resource.programs[0].filters = this.userPerimeterEntities;
        }

        if (this.isDuplicableModel) {
            this.nudge.id = undefined;
            this.nudge.resource.id = undefined;
            this.nudge.resource.programs[0].company_program_id = this.companyProgramId;
            this.nudge.resource.programs[0].company_program_key_date_id = null;
            this.nudge.resource.programs[0].id = undefined;
            for (const participant of this.nudge.resource.programs[0].participants) {
                participant.id = undefined;
            }
        }
        this.nudgeLoaded = true;
        this.modal.setTitle(I18n.translate(this.id ? 'Edit nudge' : 'Add nudge'));
    },

    methods: {
        openAddInitiatorUsersModal() {
            this.allowUserSearch = true;
            this.isAssignableOnboardee = false;
            this.isSearchUserMultipleMode = false;
            this.$refs.searchRolePanel.init({
                title: this.translate('Nudge initiator'),
                selected: [this.companyNudgeInitiator],
                onUnselect: this.onRemoveInitiator,
                onSelect: (selectedInitiator) => {
                    this.companyNudgeInitiator = selectedInitiator;
                    this.$refs.modalableSearchRolePanel.close();
                },
            });
            this.$refs.modalableSearchRolePanel.open();
        },

        onRemoveInitiator() {
            this.companyNudgeInitiator = null;
        },

        openAddUsersModal() {
            this.allowUserSearch = false;
            this.isAssignableOnboardee = true;
            this.isSearchUserMultipleMode = true;
            this.$refs.searchRolePanel.init({
                title: this.translate('Nudge recipient'),
                onValidate: this.addRecipients,
                selected: this.nudge.resource.programs[0].participants,
            });
            this.$refs.modalableSearchRolePanel.open();
        },

        addRecipients(recipients) {
            this.nudge.resource.programs[0].participants = recipients.map((participant) => {
                const role = participant.company_role;
                // delete role['company_role'];
                return {
                    role,
                    company_role: role,
                    company_role_id: role.id,
                };
            });
        },

        onRemoveRecipient(recipient) {
            this.nudge.resource.programs[0].participants = this.nudge.resource.programs[0].participants.filter(
                (companyNudgeParticipantItem) => companyNudgeParticipantItem.role.id !== recipient.role.id,
            );
        },

        async onValidate() {
            await this.$validator.validateAll();
            if (undefined === this.nudge.company_nudge_initiator_user_id && undefined === this.nudge.company_nudge_initiator_role_id) {
                this.$Utils.scrollToFirstError(this);
                this.$Notifier('App').showError(this.translate('You must select the initiator of the nudge'));
                return;
            }
            if (this.nudge.resource.programs[0].participants.length === 0) {
                this.$Utils.scrollToFirstError(this);
                this.$Notifier('App').showError(this.translate('You need to have at least one recipient'));
                return;
            }
            const defaultLanguage = this.shared.session.company.company_language.key;
            if (!this.nudge.translations.message[defaultLanguage]) {
                this.currentLanguage = defaultLanguage;
                this.$Utils.scrollToFirstError(this);
                this.$Notifier('App').showError(this.translate('You must enter the message in the default language'));
                return;
            }
            if (!this.nudge.resource.translations.name[defaultLanguage]) {
                this.currentLanguage = defaultLanguage;
                this.$Utils.scrollToFirstError(this);
                this.$Notifier('App').showError(this.translate('You must enter the name in the default language'));
                return;
            }

            if (!this.errors.any()) {
                const isNewResourceCreated = !this.nudge.id;
                this.buttonState = 'loading';

                let request;

                const resourceToSave = cloneDeep(this.nudge);
                if (!resourceToSave.resource.programs[0].company_program_id) {
                    resourceToSave.resource.programs = [];
                }

                if (isNewResourceCreated) {
                    request = api.configuration.nudges.create(resourceToSave);
                } else {
                    request = api.configuration.nudges.update(this.nudge.id, resourceToSave);
                }
                request.then(() => {
                    this.$emit('onSave');
                    this.$emit('onClose');
                    this.modal.close();
                }).catch(() => {
                    this.buttonState = 'idle';
                });
            } else {
                this.currentLanguage = this.shared.session.company.company_language;

                this.$Utils.scrollToFirstError(this);
            }
        },

        async onDestroyModal() {
            if (await this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this nudge? Once you click on delete, you will no longer be able to access this nudge.') })) {
                await api.configuration.nudges.delete(this.nudge.id);
                this.modal.close();
                this.$emit('onDelete');
                this.$emit('onClose', true);
            }
        },

        hasKeyDateTranslated(program) {
            return program?.key_date.name_translated !== null && program?.key_date.name_translated !== undefined;
        },
    },
};
</script>

<style lang='scss' scoped src='./NudgeEdit.scss'></style>
